import { template as template_51751bf66d8b4e1d89e3acdcf8147a16 } from "@ember/template-compiler";
const FKLabel = template_51751bf66d8b4e1d89e3acdcf8147a16(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
