import { template as template_f9a96423e70543ac8285b004f1bdbce1 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f9a96423e70543ac8285b004f1bdbce1(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
