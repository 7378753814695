import { template as template_52d81e5a3b754fb2b144fb312b851d67 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
export default class RenderGlimmerContainer extends Component {
    @service
    renderGlimmer;
    static{
        template_52d81e5a3b754fb2b144fb312b851d67(`
    {{~#each this.renderGlimmer._registrations as |info|~}}
      {{~#in-element info.element insertBefore=null~}}
        <info.component
          @data={{info.data}}
          @setWrapperElementAttrs={{info.setWrapperElementAttrs}}
        />
      {{~/in-element~}}
    {{~/each~}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
