import { template as template_63ee371579534b7f833d182b78b6a1e3 } from "@ember/template-compiler";
const WelcomeHeader = template_63ee371579534b7f833d182b78b6a1e3(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
